import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

const renderFeaturedArticle = (featuredArticle) => {
  const featuredImageSrc = !!featuredArticle.image.childImageSharp ? featuredArticle.image.childImageSharp.fluid.src : featuredArticle.image;
  return (
    <div className="content">
      <div className="tile">
        <h2 className="title">{featuredArticle.title}</h2>
      </div>

      <div className="columns">
        <div
          className="column is-6 featured-article-img"
        >
          <img src={featuredImageSrc} alt="beijing great wall" />
        </div>
        <div className="column is-6">
          <p>{featuredArticle.paragraph1}</p>
          <p>{featuredArticle.paragraph2}</p>
        </div>
      </div>

      <button><a href={featuredArticle.link}>read more</a></button>
    </div>
  );
};

const renderMiniArticles = (otherArticles) => {
  return (
    <div className="columns">
      {otherArticles.map(renderSingleArticle)}
    </div>
  );
};

const renderSingleArticle = ({ image = {}, title, blurb, link }) => {
  const articleImageSrc = image.childImageSharp ? image.childImageSharp.fluid.src : image;

  return (
    <div className="column is-4 other-articles">
      <div className="img-container">
        <img src={articleImageSrc} alt="single article" />
      </div>
      <h3 className="title">{title}</h3>
      <p>{blurb}</p>
      <button><a href={link}>{link ? 'read more' : 'coming soon'}</a></button>
    </div>
  );
};

export const IndexPageTemplate = ({
  image,
  title,
  subheading,
  link,
  featuredArticle = { image: {} },
  otherArticles = [],
}) => {
  const mainBkgdImage = !!image.childImageSharp ? image.childImageSharp.fluid.src : image;

  return (
    <div className="index-page">
      <div className="full-width-image" style={{ backgroundImage: `url(${mainBkgdImage})` }}>
        <div className="main-image__content">
          <h1>{title}</h1>
          <h3>{subheading}</h3>
          <button><a href={link}>read more</a></button>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-12">
                  {renderFeaturedArticle(featuredArticle)}
                  <hr />
                  {renderMiniArticles(otherArticles)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  featuredArticle: PropTypes.object,
  otherArticles: PropTypes.array,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        link={frontmatter.link}
        featuredArticle={frontmatter.featuredArticle}
        otherArticles={frontmatter.otherArticles}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subheading
        link
        featuredArticle {
          title
          paragraph1
          paragraph2
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link
        }
        otherArticles {
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          blurb
          link
        }
      }
    }
  }
`
